<template>
  <div class="categories">
    <b-container fluid="xxl">
      <b-row class="text-center">
        <b-col>
          <input
            class="px-2 py-1 w-100"
            id="search"
            type="text"
            size="sm"
            placeholder="Search categories"
            @keyup="getSearchResults()"
            autocomplete="off"
            v-model="query"
          />
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <ul v-if="categories.length" class="results-ul">
            <li v-for="category in categories" :key="category.id">
              <a href="#" @click="selectCategory($event, category)">{{ category.concept }}, {{ category.fullCode }}</a>
            </li>
          </ul>
          <span v-else class="opacity-60">
            <span v-if="query">No results</span>
            <span v-else>Start searching</span>
          </span>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'CategoryAutocomplete',
    props: ['exclude'],
    data() {
      this.$store.state.category.categories = []
      return {
        query: '',
        timer: null
      }
    },
    mounted() {
      this.$store.state.category.categories = []
    },
    computed: {
      ...mapState({
        categories: state => state.category.categories
      }),
      clearQuery(){
        return this.query.replace(/\*/gi, '')
      }
    },
    methods: {
      selectCategory(event, category){
        event.preventDefault()
        this.$emit('select', category)
        this.$store.state.category.categories = []
        this.query = ''
      },
      getSearchResults(){
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.$store.dispatch('searchCategories', {
            query: this.query.toLowerCase(),
            exclude: (this.exclude ? this.exclude : [])
          })
        }, 400)
      }
    }
  }
</script>
