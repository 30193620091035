<template>
  <div class="sense">
    <div v-if="loading || !sense">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
            <b-col class="mt-4">
              <div class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle v-bind:title="'Sense details'" :pretitle="''" />
          </b-col>
          <b-col>
            <div class="mt-4 text-right">
              <span v-if="editMode && config.editable">
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button @click="deleteItem" v-if="$store.getters.checkRole('legam.sem.sense.delete')" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
              <span v-else>
                <button @click="editMode = true" v-if="$store.getters.checkRole('legam.sem.sense.modify')" class="btn-legam-xs mb-2">
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
                <button @click="deleteItem" v-if="!$store.getters.checkRole('legam.sem.sense.modify') && $store.getters.checkRole('legam.sem.sense.delete')" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <article>
              <b-row>
                <b-col>
                  <h6>Definition</h6>
                  <b-form-textarea
                    id="definition"
                    placeholder="Definition"
                    v-model="sense.definition"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else v-html="sense.definition"></span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <h6>Paradefinition</h6>
                  <b-form-textarea
                    id="paradefinition"
                    placeholder="Paradefinition"
                    v-model="sense.paradefinition"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else v-html="sense.paradefinition"></span>
                </b-col>
                <b-col>
                  <h6>Designation</h6>
                  <b-form-textarea
                    id="designation"
                    placeholder="Designation"
                    v-model="sense.designation"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else v-html="sense.designation"></span>
                </b-col>
              </b-row>
            </article>
          </b-col>
          <!-- <b-col>
            <b-row class="mt-3">
              <b-col>
                <h5>Categories</h5>
                <div v-for="category in sense.categories" :key="category.id">
                  <h6>Concept</h6>
                  {{ category.concept }}
                  <h6>Visible</h6>
                  {{ category.visible }}
                  <h6>Code</h6>
                  {{ category.code }}
                  <h6>Ontology</h6>
                  <div v-if="category.ontology" style="padding-left: 17px">
                    <h6>Name</h6>
                    {{ category.ontology.name }}
                    <h6>IsReadonly</h6>
                    {{ category.ontology.isReadonly }}
                    <h6>Abbreviation</h6>
                    {{ category.ontology.abbreviation }}
                    <h6>Definition</h6>
                    {{ category.ontology.definition }}
                    <h6>URL</h6>
                    {{ category.ontology.url }}
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col> -->
        </b-row>
        <hr class="mt-4">
        <div class="accordion mt-4" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-4 class="text-left">
                Categories <small>({{ sense.categories.length }} entries)</small>
                <span class="when-open float-right">-</span>
                <span class="when-closed float-right">+</span>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-4" accordion="my-accordion-4" role="tabpanel">
              <b-card-body>
                <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-category>
                  <b-icon icon="plus"></b-icon>
                  Add
                </span>
                <b-modal id="modal-add-category" title="Add category" ok-only ok-variant="secondary" size="lg">
                  <CategoryAutocomplete @select="addCategory" />
                </b-modal>
                <CategoryTable
                  :showTotal="false"
                  :categories="sense.categories"
                  @delete="deleteCategory"
                  :editMode="editMode"
                  v-if="sense.categories.length"
                />
                <span v-else>No data</span>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div class="accordion" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-1 class="text-left">
                Lemmas <small>({{ sense.lemmas.length }} entries)</small>
                <span class="when-open float-right">-</span>
                <span class="when-closed float-right">+</span>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" accordion="my-accordion-1" role="tabpanel">
              <b-card-body>
                <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-lemma>
                  <b-icon icon="plus"></b-icon>
                  Add
                </span>
                <b-modal id="modal-add-lemma" title="Add lemma" ok-only ok-variant="secondary" size="lg">
                  <LemmaAutocomplete @select="addLemma" />
                </b-modal>
                <LemmaTable
                  :showTotal="false"
                  :lemmas="sense.lemmas"
                  @delete="deleteLemma"
                  :editMode="editMode"
                  v-if="sense.lemmas.length"
                />
                <span v-else>No data</span>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div class="accordion" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-2 class="text-left">
                Occurrences <small>({{ sense.occurrences.length }} entries)</small>
                <span class="when-open float-right">-</span>
                <span class="when-closed float-right">+</span>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-2" accordion="my-accordion-2" role="tabpanel">
              <b-card-body>
                <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-occurrence>
                  <b-icon icon="plus"></b-icon>
                  Add
                </span>
                <b-modal id="modal-add-occurrence" title="Add occurrence" ok-only ok-variant="secondary" size="lg">
                  <OccurrenceAutocomplete @select="addOccurrence" />
                </b-modal>
                <OccurrenceTable
                  :showTotal="false"
                  :occurrences="sense.occurrences"
                  @delete="deleteOccurrence"
                  :editMode="editMode"
                  v-if="sense.occurrences.length"
                />
                <span v-else>No data</span>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div class="accordion" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-3 class="text-left">
                References <small>({{ sense.references.length }} entries)</small>
                <span class="when-open float-right">-</span>
                <span class="when-closed float-right">+</span>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-3" accordion="my-accordion-3" role="tabpanel">
              <b-card-body>
                <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-reference>
                  <b-icon icon="plus"></b-icon>
                  Add
                </span>
                <b-modal id="modal-add-reference" title="Add reference" ok-only ok-variant="secondary" size="lg">
                  <ReferenceAutocomplete @select="addReference" />
                </b-modal>
                <ReferenceTable
                  :showTotal="false"
                  :references="sense.references"
                  @delete="deleteReference"
                  :editMode="editMode"
                  v-if="sense.references.length"
                />
                <span v-else>No data</span>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <hr class="mt-4">
        <div class="mt-4" v-if="config.editable">
          <h6>Logs</h6>
          <ChangeLogsTable :objectType="'sense'" :objectId="senseId" :key="'logs' + index" />
        </div>
      </b-container>
    </div>
  </div>
</template>

<style scoped>
  h6 {
    margin-bottom: 2px;
    margin-top: 1rem;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import ChangeLogsTable from '@/components/ChangeLogsTable.vue'
  import LemmaTable from '@/components/Lemma/Table.vue'
  import OccurrenceTable from '@/components/OccurrenceTable.vue'
  import ReferenceTable from '@/components/ReferenceTable.vue'
  import CategoryTable from '@/components/CategoryTable.vue'
  import LemmaAutocomplete from '@/components/Lemma/Autocomplete.vue'
  import OccurrenceAutocomplete from '@/components/OccurrenceAutocomplete.vue'
  import ReferenceAutocomplete from '@/components/ReferenceAutocomplete.vue'
  import CategoryAutocomplete from '@/components/CategoryAutocomplete.vue'
  import config from '@/config.js'

  export default {
    name: 'Sense',
    data(){
      return {
        config: config,
        senseId: this.$route.params.id,
        loading: true,
        editMode: false,
        index: 0
      }
    },
    components: {
      PageTitle,
      ChangeLogsTable,
      LemmaTable,
      LemmaAutocomplete,
      OccurrenceTable,
      OccurrenceAutocomplete,
      ReferenceTable,
      ReferenceAutocomplete,
      CategoryTable,
      CategoryAutocomplete
    },
    watch: {
      'sense'() {
        this.loading = false
      }
    },
    computed: {
      ...mapState({
        sense: state => state.sense.sense
      })
    },
    mounted(){
      this.$store.dispatch('loadSense', {
        senseId: this.$route.params.id
      })
    },
    methods: {
      cancelEdit(){
        this.editMode = false
        this.$store.dispatch('loadSense', {
          senseId: this.senseId
        })
      },
      saveEdit(){
        this.editMode = false
        this.loading = true
        this.$store.dispatch('saveSense', this.sense).then(() => {
          this.$store.dispatch('loadSense', {
            senseId: this.senseId
          })
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Changes saved`
          })
          this.index++
        })
      },
      deleteItem(){
        this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
          if (value){
            this.$store.dispatch('deleteSense', this.sense).then(() => {
              router.push('/senses')
            })
          }
        })
      },
      addLemma(lemma){
        this.$bvModal.hide('modal-add-lemma')
        this.sense.lemmas.push(lemma)
      },
      deleteLemma(lemmaId){
        this.sense.lemmas = this.sense.lemmas.filter(lemma => lemma.id != lemmaId)
      },
      addOccurrence(occurrence){
        this.$bvModal.hide('modal-add-occurrence')
        this.sense.occurrences.push(occurrence)
      },
      deleteOccurrence(occurrenceId){
        this.sense.occurrences = this.sense.occurrences.filter(occurrence => occurrence.id != occurrenceId)
      },
      addReference(reference){
        this.$bvModal.hide('modal-add-reference')
        this.sense.references.push(reference)
      },
      deleteReference(referenceId){
        this.sense.references = this.sense.references.filter(reference => reference.id != referenceId)
      },
      addCategory(category){
        this.$bvModal.hide('modal-add-category')
        this.sense.categories.push(category)
      },
      deleteCategory(categoryId){
        this.sense.categories = this.sense.categories.filter(category => category.id != categoryId)
      },
      selectCategory(category){
        let categoryIds = this.sense.categories.map(category => category.id)
        if (!categoryIds.includes(category.id)){
          this.sense.categories.push(category)
        }
      }
    }
  }
</script>
